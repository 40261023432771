<template>
  <div>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
试试<br/>
  </div>
</template>
<script>
export default {
    name: 'About',
    data() {
        return {
           logo:require('../assets/img/Logos@2.png'),
           type:3,
        }
    },
    mounted() {
        
    },
     methods: {
      _step(){
         var type = this.type;
         switch(type){
            case 1:
              this.type = 2;
              break;
            case 2:
              this.type = 3;
              break;
            case 3:
              this.type = 4;
              break;  
            default:
              this.type = 1;
         }
         document.querySelector("#span-"+type).classList.remove('atv');
         document.querySelector("#span-"+this.type).classList.add('atv');
      }
    }
}
</script>
<style>
  body{
    background: #000;
  }
	#app{
		height:99.8%;
    background: #F1F1F1;
	}
	.main-box{
		height:100%;
		
	}
	.content{
			height:100%;
		}
@media screen and (max-width:1079px){
	#app{
		background: #F1F1F1;

	}
}
</style>